import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const contactPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="Contact us" />
		<Container py="40px">
			<h1> Contact Us </h1>
			<p>email: info@indusfoods.us <br/> whatsapp: +1 (309) 989-4444 </p>
		</Container>		
	</PrimaryLayout>
);

export default contactPage;
